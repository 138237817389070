.category-page-section {
    .tour_list {
        text-align: center;

        li {
            display: inline-block;
            text-align: left;
            float: none;

            @media (min-width: $mobile + 1) and (max-width: $tablet) {
                width: 49%;
                margin: 3px;

                &:nth-child(3n+3) {
                    margin-right: 3px;
                }
            }

            @media (max-width: $mobile) {
                display: block;
            }

            p {
                min-height: 57px;
            }
        }
    }
}
