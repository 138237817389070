@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 400;
    src: url("/static/fonts/2DA151_4_0.eot");
    src: url("/static/fonts/2DA151_4_0.eot?#iefix") format("embedded-opentype"), url("/static/fonts/2DA151_4_0.woff2") format("woff2"), url("/static/fonts/2DA151_4_0.woff") format("woff"), url("/static/fonts/2DA151_4_0.ttf") format("truetype");
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 700;
    src: url("/static/fonts/2DA151_5_0.eot");
    src: url("/static/fonts/2DA151_5_0.eot?#iefix") format("embedded-opentype"), url("/static/fonts/2DA151_5_0.woff2") format("woff2"), url("/static/fonts/2DA151_5_0.woff") format("woff"), url("/static/fonts/2DA151_5_0.ttf") format("truetype");
}
