@mixin Rubik {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
@mixin Rubik-Medium {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
@mixin ProximaNova {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
}
