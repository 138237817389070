@charset "UTF-8";

@import "variables/fonts";
@import "variables/sizes";
@import "variables/colors";
@import "variables/mixins";

@import "components/jquery-ui-default";
@import "components/jquery-ui";
@import "components/booking_form";
@import "components/intlTelInput";
@import "components/questions";
@import "components/instagram_gallery";
@import "components/people_button";

@import "pages/booking";
@import "pages/tour";
@import "pages/category";
@import "pages/schedules";
@import "pages/reviews";
@import "pages/page";

@import "site_overrides/sidetrip";

html,
body {
    height: 100%;
}

.lazyImage {
    &[data-lazy-src] {
        // filter: blur(20px);
    }
}

.pull {
    &-left {
        float: left;
    }

    &-right {
        float: right;
    }
}

.hide_on_mobile {
    @media (max-width: $tablet) {
        display: none !important;
    }
}

.hide_on_desktop {
    @media (min-width: $tablet) {
        display: none !important;
    }
}
.dont_hide_on_desktop{
    margin: 30px 0;
    font-size: 17px;
    font-family: "ProximaNova-Regular", sans-serif;
    line-height: 1.3;
    text-align: center;
    color: #424547;
}

.overflow {
    &-hidden {
        overflow: hidden;

        &-mobile {
            @media (max-width: $mobile) {
                overflow: hidden;
            }
        }

        &-tablet {
            @media (min-width: $mobile + 1) and (max-width: $tablet) {
                overflow: hidden;
            }
        }

        &-devices {
            @media (max-width: $tablet) {
                overflow: hidden;
            }
        }

        &-desktop {
            @media (min-width: $tablet + 1) {
                overflow: hidden;
            }
        }
    }
}

.active {
    color: $green !important;
}

body {
    margin: 0px;
    color: $gray;
    font-family: "ProximaNova", sans-serif;
    font-size: 16px;
    line-height: 1.2;

    @media (max-width: $tablet) {
        font-size: 14px;
    }

    &.no-scrolling {
        overflow: hidden;
    }
}

* * {
    outline: none;
}

h1 {
    margin: 18px 0px 20px;
    color: #424547;
    font-size: 42px;
    line-height: 1;
    font-weight: 500;

    @media (max-width: $tablet) {
        font-size: 20px;
        margin: 20px 0px 20px;
        text-align: left;
    }

    small {
        font-size: 19px;

        @media (max-width: $tablet) {
            font-size: 15px;
        }
    }
}

p {
    font-size: 16px;

    @media (max-width: $tablet) {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 15px;
    }

    &.large {
        margin: 40px 0px 0px;
        font-size: 19px;
        font-weight: 400;
        font-family: "Rubik", sans-serif;
        line-height: 25px;
        letter-spacing: 0px;

        @media (max-width: $tablet) {
            margin-top: 16px;
            font-size: 15px;
        }
    }
}

h1,
h2,
h3,
h4 {
    font-family: "Rubik", sans-serif;
}

h2 {
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0px;
    margin: 40px 0px 20px;

    @media (max-width: $tablet) {
        margin: 16px 0px;

        &:first-child {
            margin-top: 30px;
        }
    }

    &.decorated_a {
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 2px;
        text-transform: uppercase;
        border-left: 5px solid #2bb656;
        padding-left: 12px;
        margin: 60px 0px 30px;
        position: relative;

        @media (max-width: $tablet) {
            margin: 13px 0px;
            padding-left: 10px;
            padding-right: 70px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &.decorated {
        border: 0px;
        padding-left: 0px;
        padding-right: 0px;
        color: $green;
        text-align: center;
        text-transform: uppercase;
        line-height: 20px;
        letter-spacing: 2px;
        margin: 60px 0px 40px;

        @media (max-width: $tablet) {
            font-size: 15px;
            line-height: inherit;
            margin: 10px 0px;
        }

        &:before,
        &:after {
            display: block;
            content: "";
            width: 50%;
            height: 3px;
            background: $green;
        }

        &:before {
            margin-left: 50%;
            margin-bottom: 10px;
            background: linear-gradient(
                to right,
                $green 0%,
                $green 289px,
                rgba(255, 255, 255, 0) 289px
            );

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }

        &:after {
            margin-top: 10px;
            background: linear-gradient(
                to left,
                $green 0%,
                $green 289px,
                rgba(255, 255, 255, 0) 289px
            );

            @media (max-width: $tablet) {
                margin-bottom: 30px;
            }
        }

        &.tallinn-riga {
            color: $blue;

            &:before,
            &:after {
                background: $blue;
            }
        }
    }

    small {
        font-size: inherit;
        font-weight: 400;
    }

    .see_all {
        position: absolute;
        right: 0px;
        top: 0px;
        padding-right: 20px;
        background: url(/static/img/icon_arrow_right_green.svg) no-repeat right
            center;
        text-decoration: none;
        color: #2bb656;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        display: none;

        @media (max-width: $tablet) {
            font-size: 10px;
            top: 5px;
            right: 10px;
            background-position-y: top;
        }
    }
}

h3 {
    font-family: "Rubik", sans-serif;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;
    color: #424547;

    @media (max-width: $tablet) {
        font-size: 15px;
        line-height: 17px;
    }
}

a:hover,
button:hover {
    // opacity: 0.75;
}

.dragdealer.active {
    color: inherit !important;
}

button:hover {
    cursor: pointer;
}

.navbar {
    position: fixed;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 35px;
    background: #ffffff;
    transition: background-color 0.5s ease;
    z-index: 2;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

    &-without-image {
        background: #ffffff !important;
        position: fixed !important;
        transition: box-shadow 0.5s ease;

        a {
            color: #424547 !important;
        }
        .logo {
            background-image: url("/static/img/traveller_logo_green.svg") !important;
        }
        &.with-shadow {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25) !important;
        }
        ul.menu > li.submenu > a {
            background-image: url(/static/img/icon_arrow_header_gray.svg) !important;
        }
    }

    @media (min-width: $mobile + 1) and (max-width: $tablet) {
        padding: 0px 20px;

        .hide_on_mobile {
            display: block !important;
        }

        .hide_on_desktop {
            display: none !important;
        }
    }

    @media (max-width: $mobile) {
        padding: 0px 10px;
    }

    &.top {
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0) 100%
        );
        box-shadow: none;

        .logo {
            background-image: url(/static/img/traveller_logo.svg);
        }

        ul.menu > li a {
            color: #ffffff;
        }

        ul.menu > li.submenu > a {
            background-image: url(/static/img/icon_arrow_header_white.svg);
        }
    }

    .logo {
        display: block;
        float: left;
        width: 106px;
        height: 70px;
        background: url(/static/img/traveller_logo_green.svg) no-repeat left;
        background-size: 100%;

        @media (max-width: $mobile + 1) {
            height: 50px;
        }
    }

    ul.menu {
        float: right;
        margin: 0px;
        padding: 0px;
        list-style-type: none;

        & > li {
            position: relative;
            float: left;
            margin: 0px 15px;
            font-family: "Rubik", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 70px;
            letter-spacing: 1px;
            color: #ffffff;
            text-transform: uppercase;

            @media (max-width: $mobile + 1) {
                position: static;
                font-size: 12px;
                line-height: 50px;
                margin: 0;
            }

            &:last-child {
                @media (min-width: $tablet) {
                    margin-left: 65px;
                }
            }

            &.submenu {
                & > a {
                    background: url(/static/img/icon_arrow_header_gray.svg)
                        no-repeat right center;
                    padding-right: 21px;
                    cursor: pointer;
                }

                ul.main-submenu {
                    list-style-type: none;
                    position: absolute;
                    left: -20px;
                    padding: 15px 20px;
                    background: #ffffff;
                    line-height: 35px;
                    white-space: nowrap;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
                    display: none;
                    border-radius: 0;

                    @media (min-width: $mobile) and (max-width: $mobile + 1) {
                        right: 0;
                        left: auto;
                    }

                    @media (max-width: $mobile) {
                        position: fixed;
                        top: 50px;
                        left: 0;
                        right: 0;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        bottom: 0;
                        overflow: hidden;
                        overflow-y: auto;
                        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
                    }

                    li {
                        border-radius: 0;

                        &.separator {
                            margin: 15px -20px;
                            border-bottom: 1px solid #f2f2f2;
                        }

                        @media (max-width: $mobile + 1) {
                            margin: 0 20px;
                        }

                        a {
                            color: #424547;
                            display: block;
                            height: 35px;
                            border-radius: 0;

                            @media (max-width: $mobile + 1) {
                                // height: 46px;
                            }

                            &.icon {
                                padding: 5px 0;
                                padding-left: 30px;
                                background-position: left center;
                                background-repeat: no-repeat;

                                // -webkit-background-size: 22px;
                                // -moz-background-size: 22px;
                                // background-size: 22px;

                                &.icon-day-trips {
                                    background-image: url(/static/img/icon_daytrips_gray.svg);
                                }

                                &.icon-walking-bike-tours {
                                    background-image: url(/static/img/icon_walking_gray.svg);
                                }

                                &.icon-cruise-excursions {
                                    background-image: url(/static/img/icon_cruise_gray.svg);
                                }

                                &.icon-food-drink-tours {
                                    background-image: url(/static/img/icon_daytrips_gray.svg);
                                }

                                &.icon-multiday-tours {
                                    background-image: url(/static/img/icon_multiday_gray.svg);
                                }

                                &.icon-active-tours {
                                    background-image: url(/static/img/icon_active_gray.svg);
                                }
                            }
                        }

                        ul.languages {
                            text-align: center;
                            margin: 10px -20px 0px -20px;
                            background: #f2f2f2;
                            border-radius: 0;

                            @media (max-width: $mobile) {
                                margin: 0;
                                padding: 0;
                                position: absolute;
                                bottom: -100px;
                                left: 0;
                                right: 0;
                                width: 100%;
                                height: 50px;
                                border-radius: 0;
                                line-height: 50px;
                            }

                            li {
                                display: inline-block;
                            }
                        }
                    }
                }

                @media (min-width: $tablet) {
                    &:hover ul {
                        display: block;
                    }
                }
            }

            a {
                color: #424547;
                text-decoration: none;
            }
        }
    }
}

.section {
    // overflow: auto;
    padding-bottom: 60px;
    padding-top: 1px;

    &:nth-child(even) {
        background-color: #f2f2f2;
    }

    @media (max-width: $tablet) {
        padding-bottom: 15px;
    }

    &.header_section {
        padding: 0px;

        @media (max-width: $tablet) {
            background: #ffffff;
            border-bottom: 1px solid #f2f2f2;
        }
    }
}

.content {
    width: 1000px;
    margin: auto;

    @media (max-width: $tablet) {
        width: auto;
    }

    &.narrow {
        width: 748px;

        @media (max-width: $tablet) {
            width: auto;
        }
    }

    &.padded {
        @media (max-width: $tablet) {
            padding: 0px 22px;
        }
    }

    .description-text {
      margin: 40px 140px -20px;
      color: #424547;
      font-size: 19px;
      font-family: "ProximaNova-Regular", sans-serif;
      line-height: 26px;
      text-align: center;
    }
}

.icon-day-trips {
    background-image: url(/static/img/icon_daytrips.svg);
}

.icon-walking-bike-tours {
    background-image: url(/static/img/icon_walking.svg);
}

.icon-cruise-excursions {
    background-image: url(/static/img/icon_cruise.svg);
}

.icon-food-drink-tours {
    background-image: url(/static/img/icon_daytrips.svg);
}

.icon-multiday-tours {
    background-image: url(/static/img/icon_multiday.svg);
}

.icon-active-tours {
    background-image: url(/static/img/icon_active.svg);
}

.icon-guides {
    background-image: url(/static/img/icon_guides_gray.svg);
}

.icon-blog {
    background-image: url(/static/img/icon_blog_gray.svg);
}

ul.categories {
    width: 100%;
    margin: 0px;
    padding: 38px 0px;
    text-align: center;
    background: $green;

    & > li {
        display: inline-block;
        box-sizing: border-box;
        padding: 20px;
        width: 167px;
        vertical-align: top;

        a {
            font-family: "Rubik", sans-serif;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.6px;
            line-height: 16px;
            color: #ffffff;
            text-decoration: none;
            text-transform: uppercase;
            display: block;
            width: 100%;
            padding-top: 60px;
            background-position: top center;
            background-repeat: no-repeat;
        }
    }
}

.header {
    position: relative;
    display: table;
    width: 100%;
    height: 540px;
    text-align: center;

    @media (max-width: $tablet) {
        height: 240px;
    }

    &.small {
        height: 450px;

        &.guide-header {
            @media (max-width: $tablet) {
                height: 160px;
            }
        }
    }

    @media (max-width: $tablet) {
        &.category-header {
            height: 160px;
        }
    }

    @media (max-width: $tablet) and (min-width: $mobile) {
        &.category-header {
            height: 240px;
        }
    }

    &.with_portrait {
        height: 280px;
        margin-bottom: 90px;

        @media (max-width: $tablet) {
            margin-bottom: 0px;
            height: 240px;
        }

        @media (max-width: $tablet) and (min-width: $mobile) {
            height: 400px;
        }

        .portrait {
            width: 250px;
            height: 250px;
            border: 12px solid white;
            border-radius: 50%;
            z-index: 1;
            position: absolute;
            left: 50%;
            margin-left: -125px;
            bottom: -90px;

            background-color: lightgray;
            background-image: url("/static/traveller/images/logo2.png");
            background-position: center;
            background-size: cover;

            @media (min-width: $tablet) {
                border-width: 10px;
            }

            @media (max-width: $tablet) {
                display: none;
            }
        }
    }

    .background,
    #backgroundVideo {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        z-index: -1;
        overflow: hidden;

        &.blurred {
            @media (min-width: $tablet) {
                -webkit-filter: blur(50px);
                -moz-filter: blur(50px);
                -o-filter: blur(50px);
                -ms-filter: blur(50px);
                filter: blur(50px);
            }
        }
    }

    h1 {
        display: table-cell;
        vertical-align: middle;
        font-family: "Rubik", sans-serif;
        font-size: 50px;
        line-height: 55px;
        font-weight: 700;
        letter-spacing: 0.9px;
        color: #ffffff;
        text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
        text-align: center;
        padding: 0 10px;

        @media (max-width: $mobile) {
            font-size: 22px;
            line-height: 24px;
            padding-top: 20px;
        }

        @media (max-width: $tablet) and (min-width: $mobile) {
            font-size: 30px;
            line-height: 34px;
        }

        small {
            display: block;
            font-weight: 400;
        }

        span {
            font-weight: 400;
        }
    }

    div.categories {
        position: absolute;
        left: 0px;
        bottom: 20px;
        padding: 0px;
        width: 100%;
        background: rgba(255, 255, 255, 0);

        @media (max-width: $tablet) {
            display: none;
        }

        table {
            text-align: center;
            margin: auto;

            $block-size: 167px;

            tr {
                height: $block-size;

                td {
                    width: $block-size;
                    padding: 0;

                    a {
                        font-family: "Rubik", sans-serif;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0.6px;
                        line-height: 16px;
                        color: #ffffff;
                        text-decoration: none;
                        text-transform: uppercase;
                        width: 100%;

                        .tour-caregory-icon {
                            background-repeat: no-repeat;

                            // background-size: contain;
                            background-position: center center;
                            width: 100%;
                            height: 50px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

ul.tour_list {
    list-style-type: none;
    padding: 0px;
    font-size: 0px;

    @media (max-width: $tablet) {
        padding: 0px 8px;
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .tour-discount-badge-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        .tour-discount-badge {
            @include Rubik;
            color: #333;
            text-align: center;
            transform: rotate(45deg);
            position: relative;
            padding: 3px 0;
            right: -130px;
            top: 20px;
            width: 150px;
            margin: auto;
            background-color: $orange;
            color: #fff;
            font-size: 15px;
        }
    }

    .tour-new-badge-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        .tour-new-badge {
            @include Rubik;
            color: #333;
            text-align: center;
            transform: rotate(45deg);
            position: relative;
            padding: 3px 0;
            right: -130px;
            top: 20px;
            width: 150px;
            margin: auto;
            background-color: $red;
            color: #fff;
            font-size: 15px;
        }
    }

    &.inline {
        float: left;
        white-space: nowrap;

        li {
            float: none;
            display: inline-block;
            margin-right: 11px;

            @media (max-width: $tablet) {
                width: 304px;
                margin-right: 8px;
            }

            @media (max-width: $mobile) {
                width: 238px;
                min-height: 300px;
            }

            &:nth-child(3n + 3) {
                margin-right: 11px;
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    li {
        float: left;
        display: block;
        position: relative;
        width: 326px;
        padding: 20px;
        box-sizing: border-box;
        margin-right: 11px;
        margin-bottom: 11px;
        background: #ffffff;
        white-space: normal;
        vertical-align: top;
        font-size: 16px;
        min-height: 360px;

        &.tour-with-discount {
            .price {
                background-color: $orange;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                .tour-price {
                    @include Rubik-Medium;
                    color: $dark-gray;
                    font-size: 11px;
                    text-align: right;
                    line-height: 1;
                    text-decoration: line-through;
                    padding-top: 5px;
                    &-discount {
                        line-height: 1;
                        padding-bottom: 6px;
                        font-size: 18px;
                    }
                }
            }
        }

        p {
            // min-height: 65px;
            color: #424547;

            @media (max-width: $tablet) {
                min-height: 72px;
            }
        }

        @media (max-width: $tablet) {
            padding: 10px 14px;
            margin-left: 0px;
            margin-right: 0px;
            width: auto;
            float: none;
            min-height: 280px;
        }

        &:nth-child(3n + 3) {
            margin-right: 0px;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        .image {
            margin: -20px -20px 0px;

            @media (max-width: $tablet) {
                margin: -10px -14px 0px;
            }
        }

        .image {
            display: block;

            /*width: 326px;*/
            height: 178px;
            background: url(/static/img/background_placeholder.jpg) no-repeat
                center;
            background-size: cover;

            @media (max-width: $tablet) {
                height: 130px;
            }
        }

        .price {
            position: absolute;
            left: 0px;
            padding: 0px 14px 0px 20px;
            background: #2bb656;
            font-family: "Rubik", sans-serif;
            font-size: 19px;
            top: 114px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 40px;
            color: #ffffff;

            @media (max-width: $tablet) {
                font-size: 15px;
                line-height: 30px;
                top: 90px;
            }
        }

        h3 {
            margin: 20px 0px 0;
            font-weight: 500;

            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;

            @media (max-width: $tablet) {
                margin: 14px 0px;
            }
        }


        /* TODO: Move rating and duration into footer. */
        .footer {
            font-size: 14px;
            font-family: "Rubik", sans-serif;
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
            display: flex;
            justify-content: space-between;

            @media (max-width: $tablet) {
                bottom: 14px;
                left: 14px;
                right: 14px;
            }

            &-reviews {
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }

            &-stars {
                .stars {
                    .star {
                        width: 20px;
                        height: 20px;
                        background-image: url("/static/img/icon_star_small.svg");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 100%;
                        float: left;

                        @media (max-width: $tablet) {
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
            }

            &-rating {
                margin-left: 10px;

                @media (max-width: $mobile) {
                    margin-left: 5px;
                }
            }

            &-duration {
                width: 4.75em;
                text-align: right;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 1px;
                color: #8c8c8c;
                position: absolute;
                right: 0;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
                &-icon {
                    background-image: url(/static/img/icon_desktop_duration_tour-boxes.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 17px;
                    height: 17px;
                    padding-right: 10px;
                }

                @media (max-width: $tablet) {
                    font-size: 12px;
                    background-size: 14px;
                    background-position-y: center;
                    background-position-x: right 24px;
                }
            }

            table {
                width: 100%;

                tr td {
                    position: relative;

                    .stars {
                        position: absolute;
                        left: 0;
                        bottom: 0;

                        .star {
                            width: 20px;
                            height: 19px;
                            background: url("/static/img/icon_star_small.svg")
                                no-repeat center;
                            background-size: 100%;
                            float: left;

                            @media (max-width: $tablet) {
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }

                    .duration {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 1px;
                        padding-left: 23px;
                        background: url(/static/img/icon_desktop_duration_tour-boxes.svg)
                            no-repeat;
                        background-position: 3px 1px;
                        color: #8c8c8c;
                        position: absolute;
                        right: 0;
                        bottom: 0;

                        @media (max-width: $tablet) {
                            font-size: 12px;
                            background-size: 16px;
                            padding-left: 23px;
                            background-position-y: center;
                        }
                    }

                    .rating {
                        font-weight: 400;
                        letter-spacing: 1px;
                        position: absolute;
                        left: 0;
                        bottom: 0;

                        @media (max-width: $tablet) {
                            font-size: 12px;
                        }
                    }
                }
            }

            @media (max-width: $tablet) {
                font-size: 12px;
            }
        }
    }
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.center {
    text-align: center;
}

.reviews {
    @media (min-width: $tablet) {
        padding: 0px 125px;
    }

    .review {
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 20px;
        padding-bottom: 20px;
        line-height: 1.4;
        color: #484848;

        @media (max-width: $mobile) {
            padding-left: 22px;
            padding-right: 22px;
        }

        @media (min-width: $mobile) and (max-width: $tablet) {
            padding-left: 70px;
            padding-right: 70px;
        }

        h3 {
            margin-bottom: 10px;

            @media (max-width: $tablet) {
                margin: 10px 0px;
            }
        }

        p {
            font-size: 17px;

            @media (max-width: $tablet) {
                font-size: 15px;
            }
        }

        .stars {
            .star {
                width: 20px;
                height: 19px;
                background: url("/static/img/icon_star_small.svg") no-repeat
                    center;
                background-size: 100%;
                float: left;

                @media (max-width: $tablet) {
                    width: 17px;
                    height: 17px;
                }
            }
        }

        .footer {
            font-size: 15px;

            @media (max-width: $tablet) {
                font-size: 13px;
            }

            .separator {
                margin-left: 3px;
            }
        }

        .trip_advisor {
            @media (max-width: $tablet) {
                height: 120px;
            }
        }
    }
}

.explanation {
    p {
        margin-left: auto;
        margin-right: auto;
        width: 85%;
        font-size: 17px;
        line-height: 26px;
        color: #484848;
        font-weight: 300;
        font-family: "ProximaNova", sans-serif;

    }
    h3 {
        margin: 13px 0 14px 0;
        font-weight: 700;

    }
    .flex_container {
        display: flex;
    }

}

.private-guide-page {
    p {
        font-size: 17px;
        line-height: 1.4;
        color: #484848;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
        &-description {
            padding: 0 48px;
        }
    }

    @media (min-width: $tablet) {
        .content.padded {
            padding: 0 125px;
            box-sizing: border-box;
        }
    }
}

.guides-block {
    text-align: center;

    &.office-guides-block {
        margin-bottom: 20px;
    }

    .private-guide {
        width: 190px;
        display: inline-block;
        margin: 0 6px;
        margin-bottom: 20px;
        vertical-align: top;

        @media (max-width: $tablet) {
            width: 142px;
        }

        a {
            text-decoration: none;
            display: block;
            color: rgba(255, 255, 255, 0);
            position: relative;

            &:hover {
                z-index: 1;
                opacity: 1;

                img {
                    opacity: 0.75;
                }

                .private-guide-description {
                    left: 0;
                    right: 0;
                }
            }

            .private-guide-name {
                color: $green;
                font-size: 19px;
                letter-spacing: 1px;
                font-family: "Rubik";
                font-weight: 500;
                display: block;
                margin-bottom: 8px;

                @media (max-width: $tablet) {
                    font-size: 15px;
                }
            }

            .private-guide-description {
                width: 100%;
                display: block;
                color: #8c8c8c;
                overflow: hidden;
                font-family: "ProximaNova", sans-serif;
                font-size: 16px;
                letter-spacing: 0px;
                max-height: 100px;
                text-overflow-multiline: ellipsis;

                @media (max-width: $tablet) {
                    * {
                        font-size: 13px;
                        letter-spacing: 0px;
                        line-height: 15px;
                    }
                }

                * {
                    margin: 0;
                    font-size: 16px;
                    letter-spacing: 0px;
                }
            }

            img {
                display: block;
                width: 190px;
                height: 190px;
                border-radius: 50%;
                background: lightgray url(/static/traveller/images/logo2.png)
                    no-repeat center;
                background-size: 75%;
                margin: auto;
                margin-bottom: 20px;

                @media (max-width: $tablet) {
                    width: 142px;
                    height: 142px;
                    margin-bottom: 10px;
                }
            }

            .photo {
                width: 190px;
                height: 190px;
                border-radius: 50%;
                margin-bottom: 20px;

                background-color: lightgray;
                background-image: url("/static/traveller/images/logo2.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                @media (max-width: $tablet) {
                    width: 142px;
                    height: 142px;
                    margin-bottom: 10px;
                }
            }

            .photo:hover {
                opacity: 0.8;
            }
        }
    }
}

ul.guides {
    list-style-type: none;
    padding: 0px;
    font-size: 0;

    /* This will get rid of gaps between inline-block elements. */
    float: left;

    li {
        display: inline-block;
        margin-right: 12px;

        &:first-child {
            @media (max-width: $tablet) {
                margin-left: 10px;
            }
        }

        @media (max-width: $mobile) {
            margin-right: 6px;
        }

        @media (min-width: $mobile) and (max-width: $tablet) {
            margin-right: 9px;
        }

        img {
            display: block;
            width: 190px;
            height: 190px;
            border-radius: 50%;
            background: lightgray url(/static/traveller/images/logo2.png)
                no-repeat center;
            background-size: 75%;
            object-fit: cover;

            @media (max-width: $mobile) {
                width: 86px;
                height: 86px;
            }

            @media (min-width: $mobile) and (max-width: $tablet) {
                width: 130px;
                height: 130px;
            }
        }

        .photo-guide {
            display: block;
            width: 190px;
            height: 190px;
            border-radius: 50%;
            background-color: lightgray;
            background-image: url("/static/traveller/images/logo2.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @media (max-width: $mobile) {
                width: 86px;
                height: 86px;
            }

            @media (min-width: $mobile) and (max-width: $tablet) {
                width: 130px;
                height: 130px;
            }
        }

        .photo-guide:hover {
            opacity: 0.8;
        }

        a {
            text-decoration: none;
        }

        h3 {
            color: $green;
            text-align: center;
            font-weight: 400;

            @media (max-width: $tablet) {
                line-height: 1;
                margin: 10px 0px 30px;
            }

            @media (max-width: $mobile) {
                font-size: 13px;
            }

            @media (min-width: $mobile) and (max-width: $tablet) {
                font-size: 16px;
                margin: 15px 0 30px;
            }
        }
    }
}

.guide-title {
    margin-top: 30px;
    margin-bottom: 40px;
    color: #424547;
    font-size: 42px;
    font-family: "Rubik";
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 1.2;

    small {
        margin-top: 20px;
        display: block;
        font-size: 19px;

        @media (min-width: $tablet) {
            margin-top: 15px;
        }
    }

    @media (min-width: $tablet) {
        margin-top: 20px;
    }

    @media (max-width: $tablet) {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        small {
            margin-top: 10px;
            font-size: 15px;
        }
    }
}

.previous_guides {
    position: absolute;
    left: -40px;
    top: 78px;
    width: 20px;
    height: 34px;
    background: url(/static/img/icon_arrow_right_big_gray.svg) no-repeat center;
    transform: scaleX(-1);
    cursor: pointer;
}

.next_guides {
    position: absolute;
    right: -30px;
    top: 78px;
    width: 20px;
    height: 34px;
    background: url(/static/img/icon_arrow_right_big_gray.svg) no-repeat center;
    cursor: pointer;
}

.blog_posts {
    li {
        min-height: 300px !important;
        @media (max-width: $tablet) {
            min-height: 360px !important;
        }
        h3.blog-heading {
            font-weight: 700;
            line-height: 22px;
        }
    }

    p {
        font-size: 14px;
        color: #8c8c8c;
        overflow: hidden;
        min-height: 65px;
    }
}

#blog_posts + .center {
    margin-top: 40px;

    @media (max-width: $tablet) {
        margin-top: 0;
    }
}

.read_more {
    color: $green;
    font-size: 14px;
    padding-right: 43px;
    background: url(/static/img/icon_arrow_right_green.svg) no-repeat right 20px
        center;
    font-family: "Rubik", sans-serif;

    @media (max-width: $tablet) {
        font-size: 12px;
    }
}

.button {
    display: inline-block;
    border: 1px solid $green;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    letter-spacing: 1px;
    line-height: 38px;
    padding: 0px 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: $green;
    font-weight: 500;

    @media (max-width: $tablet) {
        font-size: 12px;
        line-height: 31px;
    }

    &.next {
        padding-right: 50px;
        background: url(/static/img/icon_arrow_right_green.svg) no-repeat right
            20px center;
    }

    &.strong {
        line-height: 48px;
        border: 0px;
        background-color: $green;
        color: #ffffff;
        text-align: center;

        &.next {
            background-image: url(/static/img/icon_arrow_right_white.svg);
            border: 1px solid #2bb656;
        }
    }
}

.col_3 {
    &:after {
        content: "";
        display: block;
        clear: both;
    }

    > * {
        float: left;
        width: 33.33%;

        @media (max-width: $tablet) {
            // width: 100%;
            float: none;
            width: 60%;
            margin: auto;
            text-align: center;
            font-size: 12px;
            line-height: 18px;

            &:after {
                content: "";
                display: block;
                margin: auto;
                width: 50px;
                height: 2px;
                background: #ffffff;
                margin-top: 10px;
            }
        }
    }
}

body > .footer {
    padding: 45px 0 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    background: #424547;
    color: #ffffff;
    line-height: 28px;
    font-family: "Rubik", sans-serif;

    @media (max-width: 1024px) {
        .content {
            width: auto;
        }
    }

    @media (max-width: $tablet) {
        padding: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .footer-col-3 {
        float: left;
        width: calc(100%/3);
        box-sizing: border-box;

        @media (min-width: $tablet + 1) {
            padding-right: 10px;
        }

        @media (max-width: $tablet) {
            float: none;
            width: 100%;
            margin-bottom: 10px;
            text-align: center;
        }

        a,
        * a {
            color: inherit;
            text-decoration: none;
            cursor: pointer;
        }

        .footer-bold-text {
            font-weight: bold;
            font-family: "Rubik-Medium", sans-serif;
        }

        &.text-right {
            text-align: right;
            padding: 0;

            @media (max-width: $tablet) {
                text-align: center;
            }
        }

        .footer-image-content {
            img {
                width: 32px;
                margin: 5px 0 5px 5px;
            }
        }
    }
}

.footer_categories_wrapper {
    background-color: #2bb656;

    $block-size: 167px;

    @media (max-width: $tablet) {
        background-color: #f2f2f2;
    }

    @media (min-width: $tablet) {
        height: $block-size;
    }

    .inner-block {
        text-align: center;
        margin: auto;

        @media (max-width: $tablet) {
            margin-bottom: -2px;
        }

        .caregories-block {
            display: inline-block;
            height: $block-size;
            width: $block-size;
            position: relative;

            @media (max-width: $tablet) {
                margin: -2px;

                &:nth-child(1) {
                    background-color: #189447;
                }

                &:nth-child(2) {
                    background-color: #1ba750;
                }

                &:nth-child(3) {
                    background-color: #2bb656;
                }

                &:nth-child(4) {
                    background-color: #59cb59;
                }

                &:nth-child(5) {
                    background-color: #75dd66;
                }

                &:nth-child(6) {
                    background-color: #83eb73;
                }
            }

            @media (min-width: $mobile) and (max-width: $tablet) {
                width: calc(100%/3);
            }

            @media (max-width: $mobile) {
                width: calc(100%/2);
            }

            &:hover {
                background-color: #1ba750;
            }

            a {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                font-family: "Rubik", sans-serif;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.6px;
                line-height: 16px;
                color: #ffffff;
                text-decoration: none;
                text-transform: uppercase;
                width: 100%;
                height: 50%;
                margin: auto;
                word-wrap: break-word;

                .tour-caregory-icon {
                    background-repeat: no-repeat;

                    // background-size: contain;
                    background-position: center center;
                    width: 100%;
                    height: 50px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.gallery {
    overflow: hidden;

    $small-size: 70px;

    // 5vw
    $large-size: 256px;

    // 20vw
    max-width: 1280px;
    margin: auto;

    ul {
        list-style-type: none;
        padding: 0px;
        float: left;
        white-space: nowrap;
        font-size: 0px;

        @media (max-width: $tablet) {
            float: none;
            width: 100%;

            &.handle {
                white-space: normal;
                line-height: inherit;
            }
        }

        li {
            display: inline-block;
            width: $large-size;
            height: $large-size;
            background: url(/static/img/background_placeholder.jpg);
            background-size: cover;

            @media (max-width: $tablet) {
                width: 33.33%;

                &:nth-child(n + 10) {
                    display: none;
                }
            }
        }
    }

    .small_thumbnails {
        @media (max-width: $tablet) {
            display: none;
        }

        ul li {
            width: $small-size;
            height: $small-size;
        }
    }

    & + .center {
        margin-top: 25px;
        margin-bottom: 15px;
    }
}

// .gallery {
//     overflow: hidden;

//     ul {
//         list-style-type: none;
//         padding: 0px;
//         float: left;
//         white-space: nowrap;
//         font-size: 0px;

//         @media (max-width: $tablet) {
//             float: none;
//             width: 100%;
//         }

//         li {
//             display: inline-block;
//             width: 20vw;
//             height: 20vw;
//             background: url(/static/img/background_placeholder.jpg);
//             background-size: cover;

//             @media (max-width: $tablet) {
//                 display: block;
//                 float: left;
//                 width: 33.33%;
//                 height: 33.33vw;

//                 &:nth-child(n + 10) {
//                     display: none;
//                 }
//             }
//         }
//     }

//     .small_thumbnails {
//         @media (max-width: $tablet) {
//             display: none;
//         }
//     }

//     .small_thumbnails ul li {
//         width: 5vw;
//         height: 5vw;
//     }
// }

.full_screen_image {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
}

.inline_collection_container {
    overflow: hidden;

    // margin-bottom: 40px;

    @media (max-width: $tablet) {
        margin-bottom: 0;
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.inline_collection {
    list-style-type: none;

    /* TODO: Not valid for all elements. */
    padding: 0px;
    font-size: 0px;
    float: left;
    white-space: nowrap;

    > * {
        display: inline-block;
    }
}

.header_image {
    width: 600px;
    height: 400px;
    background-position: center;
    background-size: cover;

    @media (max-width: $tablet) {
        width: 100vw;
        height: 66.66vw;
        max-width: 600px;
        max-height: 400px;
    }
}

.content > ul {
    list-style-image: url("/static/img/icon_star_green.svg");
    padding: 0px;
    line-height: 30px;

    li {
        position: relative;
        margin-left: 25px;

        &:before {
            color: $green;
            font-size: 32px;
            vertical-align: middle;
            position: absolute;
            left: -25px;
            top: -1px;
        }
    }
}

.starting_location {
    height: 340px;

    .starting_location_description {
        font-size: 16px;
        font-family: "Rubik", sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23px;
        color: #404040;
        padding: 11px 15px;

        p {
            margin: 0;
        }
    }
}

.fixed_availability {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $green;
    padding: 20px 0;
    font-size: 17px;
    text-align: center;
    z-index: 2;
    text-decoration: none;
    color: #fff;
    box-sizing: border-box;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
        opacity: 1;
    }
}



.partner-login-modal {
  width: 320px;
  border-radius: 4px;
  padding: 35px;

  .close-pop-up {
    font-size: 2em;
    margin: -20px;
    float: right;
    cursor: pointer;
    color: #424547;
  }

  h3 {
    margin-top: 0;
    font-size: 19px;
  }
  input {
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 19px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
  }
  input::placeholder {
    color: $gray;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
  }
  button {
    width: 100%;
  }

}

.cookies-warning {
  display: none;
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  background-color: #f2f2f2;

  span {
    padding-left: 20px;
    color: #404040;
    font-size: 14px;
    font-family: "ProximaNova", sans-serif;
    line-height: 18px;
  }

  .accept-button {
    cursor: pointer;
    margin-left: 30px;
    height: 30px;
    background-color: $green;
    color: #ffffff;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    line-height: 17px;
    border: 0;
  }

  a {
    cursor: pointer;
    margin-left: 20px;
    text-decoration: none;
    color: #8C8C8C;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    line-height: 17px;
  }
}

.cookies-margin {
  margin-top: 50px;
}

.grecaptcha-badge { visibility: hidden; }


.covid-banner {
    width: 100%;
    //background: white;
    background: #2bb656;
    //bottom: 0px;
    //top: 93px;
    top: 0px;
    text-align: center;
    //position: sticky;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 99999;
    box-shadow: 4px 0px 2px 1px rgba(55, 55, 55, .2);

    h3 {
        //color: #2bb656;
        color: white;
        margin: 6px;
        padding-right: 70px;
        padding-left: 70px;
    }
    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 70px;
        background-image: url(/static/img/icon_close_light.svg);
        background-position: center center;
        background-size: 15px;
        cursor: pointer;
        background-repeat: no-repeat;
    }
}
