/* Instagram Fullscreen picture */
.image-full-screen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #1e2123;

    .gallery-inner-block {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 580px;
        width: 780px;

        @media (max-width: $tablet) {
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            transform: translate(0, 0);
        }

        .gallery-close-button {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0;
            right: 0;
            background-image: url(/static/img/icon_close_x.svg);
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: center center;
            z-index: 999;
            cursor: pointer;
        }

        .gallery-arrow,
        .gallery-main-block {
            float: left;
            max-height: 580px;
            overflow: auto;
        }

        .gallery-arrow {
            width: 100px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50%;
            height: 580px;
            cursor: pointer;
            transition: background-size 0.1s ease-in;
            -moz-transition: background-size 0.1s ease-in;
            -ms-transition: background-size 0.1s ease-in;
            -o-transition: background-size 0.1s ease-in;
            -webkit-transition: background-size 0.1s ease-in;

            @media (max-width: $tablet) {
                width: 10vw;
                display: none;
            }

            &:hover {
                background-size: 60%;
            }

            &.left-arrow {
                background-image: url("/static/img/gallery_icon_arrow_left_white.svg");
            }

            &.right-arrow {
                background-image: url("/static/img/gallery_icon_arrow_right_white.svg");
            }
        }

        .gallery-main-block {
            width: 580px;
            position: relative;

            @media (max-width: $tablet) {
                width: 100vw;
                height: 100vh;
            }

            .gallery-image {
                height: 480px;
                width: 100%;
                background: url("https://lorempixel.com/600/800/")
                    rgba(0, 0, 0, 0.7);
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            .gallery-text {
                background-color: rgba(0, 0, 0, 0.7);
                overflow: auto;
                line-height: 19px;
                font-family: "ProximaNova";
                min-height: 100px;

                @media (max-width: $tablet) {
                }

                .gallery-text-inner {
                    padding: 20px;
                    color: #fff;
                    font-size: 16px;

                    @media (max-width: $tablet) {
                        padding: 10px;
                    }
                }
            }
        }
    }
}
