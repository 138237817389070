.ui-corner-all {
    border-radius: 0px;
}

.ui-corner-top {
    border-radius: 0px;
}

.ui-button,
.ui-button:hover,
.ui-button:focus {
    padding: 9px 20px;
}

.ui-widget,
.ui-widget:hover,
.ui-widget:focus {
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    background: #ffffff;
}

.ui-button .ui-icon-triangle-1-s,
.ui-button:hover .ui-icon-triangle-1-s,
.ui-button:focus .ui-icon-triangle-1-s {
    background: url("/static/img/icon_arrow_expand_green.svg") no-repeat center
        center;
}

/*
.ui-selectmenu-button.ui-button {
    display: inline-block;
    width: auto;
}

*/

.ui-datepicker {
    width: 360px;
    min-height: 334px;
    padding: 30px 50px;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

    @media (max-width: $mobile) {
        width: 256px;
        min-height: 0;
        padding: 2px;
    }
}

.ui-datepicker.ui-corner-all {
    // border-radius: 4px;
}

.ui-datepicker .ui-datepicker-header {
    background: rgba(255, 255, 255, 0);
    border: 0px;
    margin-bottom: 20px;

    @media (max-width: $tablet) {
        margin-bottom: 0;
    }
}

.ui-datepicker .ui-datepicker-title {
    color: #404040;
    font-size: 19px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 24px;
}

.ui-datepicker .ui-state-default {
    border: 0px;
    background: rgba(255, 255, 255, 0);
    color: $green;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
}

.ui-datepicker .ui-state-disabled .ui-state-default {
    color: #8a8a8a;
    font-weight: 500;
    opacity: 1;
}

.ui-datepicker .ui-state-active {
    color: #ffffff;
    background: $green;
    border-radius: 50%;
}

.ui-datepicker .ui-state-hover {
    text-decoration: underline;
}

.ui-datepicker th {
    text-align: center;
}

.ui-datepicker .ui-icon-circle-triangle-w {
    background: none;
}

.ui-datepicker .ui-icon-circle-triangle-e {
    background: none;
}

.ui-datepicker .ui-datepicker-prev:after {
    content: "<";
}

.ui-datepicker .ui-datepicker-next:after {
    content: ">";
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    text-align: center;
}

.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
    color: $green;
    font-size: 19px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 24px;
}

.ui-datepicker .ui-datepicker-prev-hover.ui-state-hover,
.ui-datepicker .ui-datepicker-next-hover.ui-state-hover {
    background: none;
    border-color: #ffffff;
}

.ui-datepicker * {
    font-family: "Rubik";
}

.ui-selectmenu-text {
    text-align: center;
}

.ui-widget.ui-widget-content {
    // max-height: 200px;
}
