.booking_form {
    font-family: "Rubik", sans-serif;
    text-align: center;

    * {
        font-family: "Rubik", sans-serif;
    }

    .warning-text {
        text-align: center;
        color: indianred;
    }
}

.booking-form-item {
    display: inline-block;
    margin: -2px;
    margin-right: 5px;
    width: 243px;
    vertical-align: middle;

    &:last-child {
        margin-right: -2px;
    }

    @media (max-width: $mobile) {
        display: block;
        margin: 10px 8px;
        width: auto;
    }
}

.booking_form #id_date {
    background: #ffffff;
    background-image: url("/static/img/icon_calendar.svg");
    background-position: 27px center;
    background-repeat: no-repeat;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    color: #404040;
    font-size: 16px;
    font-weight: 500;
    line-height: 46px;
    padding-bottom: 0px;
    padding-left: 71px;
    padding-top: 0px;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 0;
}

.booking_form label {
    display: block;
    margin-bottom: 15px;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    line-height: 39px;
    font-size: 14px;
}

.booking_form label input {
}

#show_availability {
    background-color: #2bb656;
    border: 1px solid #2bb656;
    box-sizing: border-box;
    color: #ffffff;
    display: block;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 46px;
    padding: 0px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: $tablet) {
        display: block;
    }
}

.booking_details {
    background: #ffffff;
    border-radius: 3px;
    margin: 10px 0px;
    position: relative;
    padding: 22px 30px;
    text-align: left;

    @media (max-width: $mobile) {
        margin-left: 8px;
        margin-right: 8px;
    }

    .hide_on_mobile {
        @media (max-width: $mobile) {
            display: none !important;
        }

        @media (min-width: $mobile + 1) and (max-width: $tablet) {
            display: inherit !important;
        }
    }

    .hide_on_desktop {
        @media (min-width: $mobile + 1) and (max-width: $tablet) {
            display: none !important;
        }
    }
}

.booking_details h4 {
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 19px;
    color: $green;

    /*padding: 30px 50px 0px;*/
    margin: 0px 0px 35px;

    @media (max-width: $mobile) {
        font-size: 14px;
    }
}

.booking_details .booking_details_content {
    /*padding: 30px 50px;*/
}

.booking_details .booking_details_content label {
    width: 230px;
    text-align: left;

    @media (max-width: $mobile) {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }

    .label-text {
        min-width: 85px;
        display: inline-block;
    }

    input,
    input[disabled] {
        border: 1px solid #2bb656;
        border-radius: 40px;
        -webkit-text-fill-color: $green;
        -webkit-opacity: 1;
        color: $green;
        display: inline-block;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        padding: 9px 20px;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        user-select: none;
        width: 80px;
        background-color: white;
    }
}

.booking_details .booking_details_content hr {
    height: 1px;
    border: 0px;
    background: #eeeeee;
    margin: 20px 0px;
}

.booking_details .booking_details_content .booking_price_section {
    position: absolute;
    top: 22px;
    right: 30px;
    text-align: right;
}

.booking_details .booking_details_content .booking_price_section .price_label {
    font-size: 14px;
    font-weight: 400;
}

.booking_details .booking_details_content .booking_price_section .price {
    margin: 10px 0px 26px;
    font-size: 50px;
    font-weight: 500;
    font-family: "Rubik", sans-serif;
    color: $green;
    line-height: 1;
}

.booking_details .booking_details_content .booking_details_footer {
    text-align: right;
    margin-top: 20px;

    @media (max-width: $mobile) {
        text-align: center;
    }
}

.booking_details.unavailable {
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    line-height: 1.6;
}

.booking_details.unavailable h4 {
    color: $gray;
    margin-bottom: 15px;
}

.booking_details.unavailable .next_available_date_container {
    // position: absolute;
    // right: 30px;
    // bottom: 22px;
    // text-align: right;
}

.booking_details.unavailable .next_available_date {
    color: $green;
    display: inline-block;
    margin-left: 10px;

    a {
        text-decoration: none;
        margin-right: 10px;
        color: inherit;
    }
}

.booking_details {
    .ui-widget {
        color: $green;
        border-radius: 40px;
        border-color: $green;
        text-transform: uppercase;
        width: 80px;
    }

    @media (max-width: $mobile) {
        input,
        .ui-widget {
            float: right;
        }
    }
}

.booking_price_table {
    display: table;
    width: 100%;
}

.booking_price_table > div {
    display: table-row;
}

.booking_price_table > div > div {
    display: table-cell;
    vertical-align: middle;
}

.booking_price_table > div > div:nth-child(1) {
    text-align: left;
}

.booking_price_table > div > div:nth-child(2) {
    text-align: right;
}

.booking_price_table > div:nth-child(2) > div:nth-child(2) {
    font-size: 30px;
    font-family: "Rubik", sans-serif;
    color: $green;
    line-height: 60px;
    font-weight: 500;
}


/**
 * Custom style for Stripe elements.
 */
.stripe-inputs {
    margin-bottom: 20px;
    padding: 1em 1em 0.5em;
    background: white;

    label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
    }

    .stripe-payment-header-row {
        min-height:2.2rem;
        border-bottom: 1px solid black;  
        position:relative;
        overflow: hidden;
        .right {
            position:absolute;
            width: 200px;
            height: 35px;
            background-repeat: no-repeat;
            background-size: contain;
            top:-10px;
            right:-54px;
        }
    }
    .row {
        margin-bottom: 20px;
    }

    .error {
        margin-top: 5px ;
        font-size: 14px;
        color: #fb5151;
    }

    input, .StripeElement {
        box-sizing: border-box;
        font-size: 16px;

        height: 40px;
        width: 100%;

        padding: 10px 12px;

        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid #ccc;
        background-color: white;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        
        &::placeholder {
            color: #aab7c4;
            font-family: "Rubik", sans-serif;
        }
    }
    input:focus {
        box-shadow: 0 1px 3px 0 #ccc;
        
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
}
