.questions_section {
    margin: 10px auto;
    background: #ffffff;
    border-radius: 3px;
    position: relative;
    box-sizing: border-box;
    padding: 20px 30px;
}

.questions_section .person {
    width: 80px;
    height: 80px;
    border-radius: 60px;
    background: gray url("/static/img/marii.jpg") no-repeat -16px;
    background-size: cover;
    position: absolute;
    top: 30px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.questions_section .ask-message {
    color: #424547;
    font-family: "Rubik";
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 20px;
}

.questions_section #ask-question-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    input {
        font-family: "Rubik";
        margin-top: 10px;
        margin-left: 23%;
        padding: 15px;
        width: 55%;
        max-height: 44px;
        background-color: #FBFBFB;
        border: 1px #DBDBDB solid;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    textarea {
        resize: none;
        color: rgb(0, 0, 0);
        font-family: "Rubik";
        font-size: 14px;
        padding: 15px;
        margin-bottom: -4.5px;
        margin-top: 10px;
        margin-left: 23%;
        width: 55%;
        height: 48px;
        background-color: #FBFBFB;
        border: 1px #DBDBDB solid;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    input {
        font-size: 14px;
    }

    button {
        margin-top: 10px;
        margin-left: 23%;
        padding: 15px;
        width: 55%;
        max-height: 48px;
        background-color: #2bb656;
        border: 0;

        font-family: "Rubik", sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #FFFF;
        font-size: 14px;

        @media (max-width: 1000px){
            font-size: 12px;
        }
    }

    input::placeholder, textarea::placeholder {
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        color: $gray;
    }

    input:focus::placeholder, textarea:focus::placeholder  {
        font-family: "Rubik", sans-serif;
        opacity: 0;
    }

    #your-email-container {
        display: none;
    }

    .feedback {
        color: #424547;
        font-weight: 400;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: "ProximaNova", sans-serif;
        .phone-number {
            font-weight: 600;
        }
    }
    
    .recaptcha-caption {
        color: #999;
        font-weight: 400;
        margin-top: 4px;
        margin-bottom: 8px;
        width: 55%;
        margin-left: 23%;
        font-size: 11px;
        font-family: "ProximaNova", sans-serif;
    }
    
}

.questions_section h3 {
    color: #424547;
    font-size: 19px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    margin-top: 40px;
    margin-left: 150px;
    margin-bottom: 40px;
}

.questions_section .button {
    position: absolute;
    top: 60px;
    right: 30px;
}

/* TODO: We have multiple similar style definitions. */
.questions_section hr {
    height: 1px;
    border: 0px;
    background: #eeeeee;
    margin: 60px 0px 20px;
}

.questions_section .customer_service_contact {
    text-align: right;
    color: #404040;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    letter-spacing: 0;
}

.questions_section .customer_service_contact .phone_number {
    font-weight: 700;
    letter-spacing: 0;
    text-decoration: none;
    color: inherit;
}

ul.questions {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

ul.questions li.question {
    border-bottom: 1px solid #eeeeee;
}

ul.questions li.question:last-child {
    border-bottom: 0px;
}

ul.questions li.question h4 {
    background: url("/static/img/icon_collapse_mobile.svg") left center no-repeat;
    cursor: pointer;
    color: #424547;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0;
    padding-left: 31px;
    margin: 15px 0;
}

ul.questions li.question.collapsed h4 {
    background: url("/static/img/icon_expand_mobile.svg") left center no-repeat;
    color: #424547;
    margin: 15px 0;
}

ul.questions li.question p {
    color: #424547;
    line-height: 21px;
}

ul.questions li.question a {
    color: #2bb656;
    text-decoration: none;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
}

ul.questions li.question.collapsed p {
    display: none;
}

ul.questions li.question:before {
    display: none;
}

#after-submit {
    display: none;
    font-size: 19px;
    margin-top: 110px;
    text-align: center;
    color: #424547;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 10px;

    .p1 {
        margin-top: -20px;
        line-height: 50px;
        font-weight: 500;
        font-size: 19px;
        font-family: "Rubik", sans-serif;

    }

    .p2, .p3 {
        line-height: 24px;
        font-size: 17px;
        font-family: "ProximaNova-Regular", sans-serif;
    }

    .phone-number {
        font-size: 17px;
        line-height: 30px;
        font-weight: 600;
        color: #424547;
    }
}
