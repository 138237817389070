.flatpage-section {
    .decorated {
        margin: 130px 0 40px;
    }

    .flatpage-text {
        background-color: #fff;
        padding: 30px;
        margin-bottom: 30px;
    }

    p {
        margin: 0;
        margin-bottom: 20px;
    }

    hr {
        margin: 20px 0;
        border: 1px solid #f2f2f2;
    }

    a {
        color: $green;
        text-decoration: none;
    }
}

.page-section {
    a {
        color: $green;
        text-decoration: none;
    }

    header {
        position: relative;
        display: table;
        width: 100%;
        height: 360px;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .header-text {
            display: table-cell;
            vertical-align: middle;
            font-family: "Rubik", sans-serif;
            font-size: 50px;
            line-height: 55px;
            font-weight: 700;
            letter-spacing: 0.9px;
            color: #ffffff;
            text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
            text-align: center;
            padding: 0 10px;

            .small {
                font-weight: 400;
            }
        }
    }

    .page-description {
        color: #484848;
        font-size: 17px;
        line-height: 26px;
        list-style: outside;
        margin: 40px 0px 0px;
        font-weight: 400;
        font-family: "ProximaNova";
        line-height: 25px;
        letter-spacing: 0px;
    }

    .page-cta {
        ul.cta-block {
            list-style-type: none;
            padding: 0px;
            font-size: 0px;
            text-align: center;

            a {
                display: block;
            }

            li {
                position: relative;
                width: 326px;
                padding: 20px;
                box-sizing: border-box;
                margin-right: 11px;
                margin-bottom: 11px;
                background: #ffffff;
                white-space: normal;
                vertical-align: top;
                font-size: 16px;
                margin: 10px;
                display: inline-block;

                &:before {
                    content: "";
                }

                .title {
                    font-family: "Rubik", sans-serif;
                    font-size: 19px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 21px;
                    color: #424547;
                }

                .image {
                    display: block;
                    height: 178px;
                    background-image: url("/static/img/background_placeholder.jpg");
                    background-repeat: no-repeat;
                    background-position: top;
                    background-size: cover;
                    margin: -20px -20px 0px;
                }
            }
        }
    }

    .page-guides {
        .guide-block {
            border-bottom: 1px solid #f2f2f2;
            padding: 30px 0;

            & > * {
                display: inline-block;
                vertical-align: middle;
            }
            .image {
                width: 200px;
                height: 200px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
            }
            .guide-text {
                padding-left: 20px;
                width: 548px;
                box-sizing: border-box;
                .guide-name {
                    color: #424547;
                    font-size: 19px;
                    font-weight: 700;
                    font-family: "Rubik", sans-serif;
                    line-height: 21px;
                    letter-spacing: 0px;
                }
                .guide-description {
                    font-family: "ProximaNova";
                    font-size: 17px;
                    line-height: 1.4;
                    color: #484848;
                }
            }
        }
    }
}
