.tour-section {
    color: #484848;

    .booking-form {
        .discount_ends_date {
            @include ProximaNova;
            font-size: 14px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 19px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 14px;
                height: 14px;
                background-repeat: no-repeat;
                background-image: url("/static/img/warning.svg");
                background-position: center center;
                background-size: 14px;
            }
        }

        .warning-text {
            text-align: center;
            margin-top: 10px;
            color: $orange;
        }
        &-ribbon {
            $orange: #ffa823;
            position: absolute;
            left: -15px;
            display: inline-block;
            background-color: $orange;
            color: white;
            padding: 8px 30px;
            font-size: 16px;
            @include ProximaNova;
            .bold {
                font-weight: 600;
            }
            &::before {
                content: ".";
                color: transparent;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 15px 15px 0;
                border-color: transparent rgba($orange, 0.4) transparent
                    transparent;
                position: absolute;
                left: 0;
                top: 35px;
            }
        }
        &-header {
            display: flex;
            position: relative;
            &-input {
                width: calc(100%/3);
                margin: 0 5px;
                input[name="date"] {
                    width: 100%;
                    box-sizing: border-box;
                    height: 100%;
                    text-align: center;
                    @include Rubik-Medium;
                    font-size: 15px;
                    background-repeat: no-repeat;
                    background-image: url("/static/img/icon_calendar.svg"),
                        url("/static/img/icon_arrow_header_gray.svg");
                    background-position: center left 15px, center right 15px;
                    border: 1px solid #cccccc;
                    cursor: pointer;
                    color: #404040;
                }
                .people-button {
                    @include Rubik-Medium;
                    background-position: center left 15px, center right 15px;
                }
            }
        }
        .loader {
          position: absolute;
          text-align: center;
          padding-top: 1.0em;
          font-weight: bold;
          z-index: 500;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: rgba(255,255,255,.99);
          margin: -.5em;
        }



        &-main {
            margin: 0 5px;
            margin-top: 20px;
            @include Rubik;
            &-item {
                background-color: white;
                padding: 20px 30px;
                position: relative;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                &-grid {
                    display: flex;
                    justify-content: space-between;
                    &.ribbon {
                        margin-top: 50px;
                    }
                    &-right {
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
                &-header {
                    @include Rubik-Medium;
                    font-size: 22px;
                    letter-spacing: 0.2px;
                    line-height: 1;
                    color: $green;
                    margin-bottom: 10px;
                }
                &-description {
                    font-size: 16px;
                }
                &-select {
                    margin-top: 20px;
                    > * {
                        display: flex;
                        align-items: center;
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                    label {
                        font-size: 14px;
                        min-width: 120px;
                    }
                    input,
                    span[role="combobox"] {
                        @include Rubik;
                        border: 1px solid $green;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-decoration: none;
                        color: $green;
                        font-weight: 500;
                        border-radius: 40px;
                        overflow: hidden;
                        text-align: center;
                        text-overflow: ellipsis;
                        text-transform: uppercase;
                        user-select: none;
                        width: 80px;
                        background-color: white;
                        height: 100%;
                        padding: 9px 20px;
                        -webkit-text-fill-color: $green;

                        &[disabled] {
                            line-height: 0;
                        }
                    }
                }
                &-price {
                    .small-text {
                        font-size: 14px;
                    }
                    .main-text {
                        @include Rubik-Medium;
                        margin: 0 0 10px;
                        font-size: 50px;
                        color: $green;
                        line-height: 1;
                        display: flex;
                        justify-content: flex-end;
                        align-items: baseline;
                        &-discount {
                            @include Rubik;
                            font-size: 22px;
                            color: #404040;
                            margin-right: 10px;
                            text-decoration: line-through;
                        }
                        &.discount {
                            color: $orange;
                        }
                    }
                }

                hr {
                    padding: 0;
                    border: 0;
                    border-top: 1px solid #f2f2f2;
                    margin: 20px 0;
                }

                &-footer {
                    @include Rubik;
                    display: flex;
                    font-size: 14px;
                    justify-content: flex-end;
                    text-align: right;
                    .price-breakdown {
                        margin-right: 10px;
                        line-height: 23px;
                        &-details {
                        }
                    }
                }

                &.unavailable {
                    font-size: 14px;
                    font-family: "Rubik", sans-serif;
                    font-weight: 400;
                    line-height: 1.6;
                    .booking-form-main-item-header {
                        color: $gray;
                    }
                }

                .next_available_date {
                    color: $green;
                    display: inline-block;
                    margin-left: 10px;

                    a {
                        text-decoration: none;
                        margin-right: 10px;
                        color: inherit;
                    }
                }
            }
        }
    }

    p,
    ul,
    p.large {
        font-size: 17px;
        line-height: 26px;
        list-style: outside;

    }

    .short-summary {
      font-size: 19px !important;
    }

    .tour-details-button {
        margin: 20px 0;
        text-align: center;
        a {
            @include Rubik-Medium;
            background-color: rgba(255, 255, 255, 0);
            text-decoration: none;
            font-size: 14px;
        }
    }

    &.tour-section-images {
        padding: 0;
        height: 400px;
        overflow: hidden;
        position: relative;

        @media (max-width: $tablet) {
            height: 240px;
        }

        .handle {
            height: 100%;

            // width: auto;
            white-space: nowrap;

            .tour-section-image {
                float: left;
                position: relative;
                width: 600px;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }

        .tour-section-images-left-arrow,
        .tour-section-images-right-arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100px;
            z-index: 1;
            background-position: center center;
            background-size: 50%;
            background-repeat: no-repeat;
            cursor: pointer;

            @media (max-width: $tablet) {
                display: none;
            }
        }

        .tour-gallery-button {
          position: absolute;
          bottom: 20px;
          right: 30px;
          color: $green;
          font-size: 14px;
          font-family: "Rubik";
          font-weight: 450;
          background-color: #fff;
          padding: 15px 20px;
          border-radius: 100px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          letter-spacing: 1px;
          z-index: 1;

          > * {
            display: inline-block;
          }

          &-label {
            margin: 0 5px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &.hide_on_mobile .tour-inline-images {
            position: relative;
            height: 100%;
        }

        .tour-inline-images-desktop {
            height: 100%;
            width: 600px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
        }

        .tour-section-images-left-arrow {
            left: 0;
            background-image: url("/static/img/gallery_icon_arrow_left_white.svg");
        }

        .tour-section-images-right-arrow {
            right: 0;
            background-image: url("/static/img/gallery_icon_arrow_right_white.svg");
        }
    }

    .margin-before-instagram-gallery {
        margin: 60px 0px 40px;

        @media (max-width: $tablet) {
            font-size: 15px;
            line-height: inherit;
            margin: 10px 0px;
        }
    }

    .tour-description-padding {
        @media (min-width: $mobile + 1) and (max-width: $tablet) {
            padding: 0 70px !important;
        }
    }
}

.tour_header {
    padding: 30px 0px 0px;
    text-align: center;

    * {
        font-family: "Rubik", sans-serif;
    }

    @media (min-width: $tablet + 1) {
    }

    @media (min-width: $mobile + 1) and (max-width: $tablet) {
    }

    @media (max-width: $mobile) {
        text-align: left;
        padding-top: 20px;
    }

    h1 {
        margin: 0px 0px 10px;
        line-height: 1.2;

        @media (min-width: $mobile + 1) and (max-width: $tablet) {
            font-size: 30px;
            line-height: 34px;
            text-align: center;
        }

        @media (max-width: $mobile) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 16px;
        }
    }

    .tour-details {
        margin: 0;
        padding: 0;
        font-size: 19px;
        line-height: 23px;
        position: relative;

        .price-discount {
            text-decoration: line-through;
            margin-right: 0;
            vertical-align: super;
            font-size: 21px;
        }

        @media (max-width: $mobile) {
            margin: 16px 0px;
            font-size: 15px;
        }

        > div {
            display: inline-block;
            vertical-align: middle;
            margin: 0px 20px 35px;

            @media (min-width: $mobile + 1) and (max-width: $tablet) {
                margin-bottom: 10px;
            }

            @media (max-width: $mobile) {
                display: block;
                margin: 10px 0px;
            }

            .stars {
                display: inline-block;
                vertical-align: bottom;

                @media (max-width: $mobile) {
                    vertical-align: baseline;
                }

                .star {
                    width: 23px;
                    height: 23px;
                    background: url("/static/img/icon_star_small.svg") no-repeat
                        center;
                    background-size: 100%;
                    float: left;

                    @media (max-width: $mobile) {
                        width: 17px;
                        height: 17px;
                    }
                }
            }

            .rating {
                margin-left: 10px;
                display: inline-block;
            }

            .duration {
                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
                .icon {
                    background-image: url(/static/img/icon_desktop_duration_tour-detail_1st_section.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 15px;
                    width: 25px;
                    height: 25px;
                }

                @media (max-width: $mobile) {
                    background-size: 16px;
                }
            }

            .group-size {
                padding-left: 30px;
                background: url(/static/img/icon_desktop_people_1st_section.svg) no-repeat left
                    center;

                @media (max-width: $mobile) {
                    background-size: 25px;
                }
            }

            .price {
                font-size: 42px;
                font-weight: 500;
                line-height: 42px;
                letter-spacing: 0px;
                color: $green;

                &.discount {
                    color: $orange;
                }
            }

            .price-container {
                @media (max-width: $mobile) {
                    position: absolute;
                    right: 0px;
                    bottom: 16px;
                }
            }
        }
    }

    ul.tour_details {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        font-size: 19px;
        line-height: 23px;
        position: relative;

        @media (max-width: $tablet) {
            margin: 16px 0px;
            font-size: 15px;
        }

        > li {
            display: inline-block;
            vertical-align: middle;
            margin: 0px 20px 35px;

            @media (max-width: $tablet) {
                display: block;
                margin: 14px 0px;
            }

            .stars {
                .star {
                    width: 20px;
                    height: 19px;
                    background: url("/static/img/icon_star_small.svg") no-repeat
                        center;
                    background-size: 100%;
                    float: left;

                    @media (max-width: $tablet) {
                        width: 11px;
                        height: 11px;
                    }
                }
            }

            .rating {
                margin-left: 10px;
            }

            .duration {
                padding-left: 29px;
                background: url(/static/img/icon_desktop_duration_tour-detail_1st_section.svg) no-repeat;
                background-position: 3px 1px;

                @media (max-width: $tablet) {
                    background-size: 16px;
                }
            }

            .group_size {
                padding-left: 36px;
                background: url(/static/img/icon_desktop_people_1st_section.svg) no-repeat left
                    center;

                @media (max-width: $tablet) {
                    background-size: 25px;
                }
            }

            .price {
                font-size: 42px;
                font-weight: 500;
                line-height: 42px;
                letter-spacing: 0px;
                color: $green;
            }

            .price_container {
                @media (max-width: $tablet) {
                    position: absolute;
                    right: 0px;
                    bottom: 16px;
                }
            }
        }
    }
}

.video_container {
    position: relative;
    width: 748px;
    height: 421px;
    margin-bottom: 10px;
    background-size: cover;

    @media (max-width: $tablet) {
        width: 100%;
        height: 56.25vw;
    }

    img {
        width: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .play {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(/static/traveller/images/video-play.png) no-repeat
            center;
        cursor: pointer;
    }
}

.tour_gallery {
    /*margin: 40px 0px;*/

    .tour_gallery_image {
        display: block;
        width: 142px;
        height: 142px;
        margin-left: 9px;
        margin-bottom: 10px;
        float: left;
        position: relative;
        background-size: cover;
        background-position: center;
        cursor: pointer;

        &:first-child {
            width: 294px;
            height: 292px;
            margin-left: 0px;
        }

        &:nth-child(7) {
            border-radius: 10px;

            &:after {
                content: "View Gallery";
                display: block;
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                background-color: rgba(43, 182, 86, 0.93);
                background-image: url(/static/img/icon_arrow_right_white.svg);
                background-repeat: no-repeat;
                background-position: center 90px;
                text-transform: uppercase;
                box-sizing: border-box;
                padding: 50px 20px;
                color: #ffffff;
                font-size: 14px;
                font-weight: 500;
                font-family: "Rubik", sans-serif;
                letter-spacing: 1px;
                line-height: 17px;
                text-align: center;
            }
        }

        &:nth-child(n + 8) {
            display: none;
        }

        @media (max-width: $tablet) {
            width: 33.33%;
            height: 33.33vw;
            margin: 0px;

            &:first-child {
                display: none;
            }
        }
    }

    @media (max-width: $tablet) {
        margin-bottom: -15px;

        /* TODO: This is a little hackish */
    }
}

.section-tour-description {
    font-size: 17px;
    line-height: 26px;
    list-style: outside;
    position: relative;
    overflow: auto;
    margin-bottom: -15px;

    h2 {
        margin: 20px 0px;
        &:first-child {
            margin: 20px 0px;
        }
    }

    &.fixed {
        height: 200px;
        overflow: hidden;
    }

    .gradient-bg {
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            #fff
        );
        background-position: top;
        background-size: 100% 65%;
        background-repeat: no-repeat;
    }

    iframe {
        width: 100%;
        height: 380px;
    }

    a {
        color: $green;
        text-decoration: none;
        font-size: 17px;
        font-weight: bold;
        cursor: pointer;
        font-family: "ProximaNova";
    }
}

.tour-images-section {
  background-color: #f2f2f2;
  padding: 90px 0 40px;

  button.upload-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
  }
}

.highlights-and-inc-section {
  margin: 0;
  line-height: 33px;
  position: relative;
  padding-left: 25px;

  span {
    font-size: 17px;
    text-align: center;
  }

  span.highlight {
    font-size: 19px;
  }

  img.pos {
    vertical-align: text-top;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

.inc-section-line-height {
  line-height: 26px;
}

.about-the-tour-section {
  margin: 0;
  padding-left: 18px;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 8px;
  }

  img.pos {
    vertical-align: text-top;
  }

  span {
    padding: 11px 11px 11px 0;
    line-height: 26px;
    font-size: 17px;
    text-align: center;

    &.not-included {
      margin-left: 5px;
    }
  }
}

.tour-additional-info-section {
    position: relative;

    &.fixed {
        height: 100px;
        overflow: hidden;
    }

    .gradient-bg {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #fff
      );
      background-position: top;
      background-size: 100% 65%;
      background-repeat: no-repeat;
    }
}

.tour-additional-button {
  margin-top: 20px;
  text-align: center;
}

.pricing-table {
  display: none;
  margin: 20px 0px 40px;
  border: 1px solid #F2F2F2;
  border-collapse: collapse;
  width: 100%;
  text-align: center;

  tr {
    border: 1px solid #F2F2F2;
    height: 48px;
  }

  th {
    padding: 20px;
    border: 1px solid #F2F2F2;
    color: #484848;
    font-size: 15px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
  }

  td {
    width: 58px;
    color: #484848;
    font-size: 15px;
    font-family: "Rubik", sans-serif;
    font-weight: 100;
    border: 1px solid #F2F2F2;

    &:nth-child(even) {
      background-color: #FBFBFB;
    }

  }

  .table-title {
    color: #484848;
    font-size: 15px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    width: 110px;
  }
}

.about-the-tour-table {
  text-align: center;
  margin-bottom: 30px;

  td {
    padding: 5px;
    font-size: 17px;

    &:nth-child(even) {
      text-align: left;
    }

    img {
      vertical-align: text-top;
    }
  }

  a {
    text-decoration: none;
    color: $green;
    font-size: 17px;
    font-family: "ProximaNova-Regular", sans-serif;
  }
}

.booking-additional-info {
  @include ProximaNova;
  color: #424547;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 0px 15px;
  margin: 20px 0px 30px;
}

.navbar {
  .price-container{
    margin-right: 12px;
    font-size: 19px;
    font-family: "Rubik", sans-serif;
    color: #484848;
  }
  .price-container.v2{
    display: inline-block;
    vertical-align: bottom;
    margin-right: 40px;
    .price{
      font-size: 42px;
      color: #2bb656;
      font-family: "Rubik", sans-serif;
      font-weight: 500;
    }
  }
  .subtitle{
    margin-bottom: 15px;
    margin-right: 12px;
  }
  input[name="date"]{
    width: 170px !important;
  }
  .people-button {
    background-image: url(/static/img/icon_people.svg),url(/static/img/icon_arrow_header_gray.svg);
    background-position: 12px center, right 15px center;
  }
  .people-button strong{
    font-family: "Rubik", sans-serif;
  }
  h3{
    margin-bottom: 10px;
    @include Rubik-Medium;
    // font-weight: 500;
    // color: #484848;
  }
  .people-button{
    width: 170px !important;
  }
  #show_availability_nav{
    font-size: 14px;
    background-color: #2bb656;
    border: 1px solid #2bb656;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    display: block;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 46px;
    padding: 0px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }
  .booking-form-header-input{
      min-width: 170px;
      height: 48px;
      display: inline-block;
      background: #fbfbfb;
      border: 1px solid #f2f2f2;
      margin: 19px 5px 0;
      input[name="date"] {
          width: 100%;
          box-sizing: border-box;
          height: 100%;
          text-align: center;
          @include Rubik-Medium;
          font-size: 15px;
          background-repeat: no-repeat;
          background-image: url("/static/img/icon_calendar.svg"),
              url("/static/img/icon_arrow_header_gray.svg");
          background-position: center left 7px, center right 15px;
          border: 1px solid #cccccc;
          cursor: pointer;
          color: #404040;
      }
      .people-button {
          @include Rubik-Medium;
          background-position: center left 15px, center right 15px;
      }
  }
  .stars {
      display: inline-block;
      vertical-align: bottom;
      margin: 0;
      margin-right: 12px;
      @media (max-width: $mobile) {
          vertical-align: baseline;
      }

      .star {
          width: 23px;
          height: 23px;
          background: url("/static/img/icon_star_small.svg") no-repeat
              center;
          background-size: 100%;
          float: left;

          @media (max-width: $mobile) {
              width: 17px;
              height: 17px;
          }
      }
  }
}

.flex-box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

h3.price{
  font-weight: normal;
  border-right: 1px solid #000;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 12px;
  padding-right: 15px;
  .bold-text{
    font-weight: 700;
  }
}

.count{
  font-family: "Rubik", sans-serif;
  font-size: 16px;;
  letter-spacing: 0px;
  line-height: 21px;
  color: #484848;
}

.navbar-nav{
  li{
    display: inline-block;
    margin-right: 30px;
    &.active a{
      font-weight: 700;
      border-bottom: 3px solid #2bb656;
    }
    a{
      text-decoration: none;
      font-size: 14px;
      color: #484848;
      font-family: "Rubik", sans-serif;
      display: block;
      padding: 12px 0;
    }
  }
}

#tour-nav {
  display: none;

  .nav-container {
    max-width: 1280px;
    margin: 0 auto;
  }

  .navbar-block {
    background-color: #FBFBFB;
    margin: 0px -35px;
  }

  .navbar-content {
    max-width: 1280px;
    margin: 0 auto;
  }

  .price-discount {
    display: inline-block;
    vertical-align: bottom;
    text-decoration: line-through;
    margin-right: 20px;
    font-size: 21px;
    font-family: "Rubik", sans-serif;
    color: #484848;
  }

  .price {
    font-size: 42px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0px;
  }

  .discount {
    color: $orange;
  }
}

@media (max-width: 1335px) {
  .navbar-block {
    padding-left: 35px;
  }
}

#private-tour {
    padding-bottom: 0;
}
.private-pricing {

    display: none;

    p {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 0;
        font-size: 16px;
        @include Rubik;
    }
    .pricing-dropdown-expand {
        padding-bottom: 20px;
        text-align: center;
        cursor: pointer;
        background-image: url("/static/img/icon_small_expand_desktop.svg");
        background-position: 69.5% 21%;
        background-repeat: no-repeat;
        font-family: "Rubik-Medium", sans-serif;

        b {
            font-size: 16px;
            font-family: "Rubik", sans-serif;
            font-weight: 500;
        }
    }
    .pricing-dropdown-content {
        display: none;
        margin-top: 0px;
        background-color: #FFFFFF;

        .dropdown-pricing-table {

            border: 1px solid #F2F2F2;
            border-collapse: collapse;
            width: 100%;
            text-align: center;

            tr {
              border: 1px solid #F2F2F2;
              height: 48px;
            }

            th {
              padding: 20px;
              border: 1px solid #F2F2F2;
              color: #484848;
              font-size: 15px;
              font-family: "Rubik", sans-serif;
              font-weight: 500;
            }

            td {
              width: 58px;
              color: #484848;
              font-size: 15px;
              font-family: "Rubik", sans-serif;
              font-weight: 100;
              border: 1px solid #F2F2F2;

              &:nth-child(even) {
                background-color: #FBFBFB;
              }

            }

            .table-title {
              color: #484848;
              font-size: 15px;
              font-family: "Rubik", sans-serif;
              font-weight: 500;
              width: 110px;
            }
        }
    }

}

.remove-icon {
  cursor: pointer;
  color: black;
  position: absolute;
  margin-top: 3px;
  margin-left: 65px;
  width: 30px;
}
