$site-10-primary-color: #f85f4a;
$site-10-secondary-color: #4c2147;

body.site-10 {

  .navbar .logo {
    background-image: url(/static/img/sidetrip_logo_color.svg);
    @media (min-width: $mobile + 50) {
      width: 140px;
    }

  }

  .navbar.top .logo {
    background-image: url(/static/img/sidetrip_logo_white.svg);
    @media (min-width: $mobile + 50) {
      width: 140px;
    }
  }

  .navbar-without-image .logo {
    background-image: url(/static/img/sidetrip_logo_color.svg) !important;
    @media (min-width: $mobile + 50) {
      width: 140px;
    }
  }

  .header:not(.category-header) h1 {
    font-weight: 400;

    span {
      font-weight: 500;
    }
  }

  .active {
    color: $site-10-primary-color !important;
  }

  ul.tour_list li .price {
    background: $site-10-primary-color;
  }

  .tour_header .tour-details > div .price {
    color: $site-10-primary-color;
  }

  .section-tour-description a {
    color: $site-10-primary-color;
  }

  #show_availability_nav {
    background-color: $site-10-primary-color;
    border-color: $site-10-primary-color;
  }

  #show_availability {
    background-color: $site-10-primary-color;
    border-color: $site-10-primary-color;
  }

  .navbar .price-container.v2 .price {
    color: $site-10-primary-color;
  }

  .navbar-nav li.active a {
    border-color: $site-10-primary-color;
  }

  .people-button .inner-block .control-elements .contol-elements-button.contol-elements-right-button {
    color: $site-10-primary-color !important;
  }

  .people-button .inner-block .control-elements .contol-elements-button.contol-elements-left-button {
    color: $site-10-primary-color !important;
  }

  .ui-datepicker .ui-datepicker-prev:after, .ui-datepicker .ui-datepicker-next:after {
    color: $site-10-primary-color;
  }

  .ui-datepicker .ui-state-default {
    color: $site-10-primary-color;
  }

  .ui-datepicker .ui-state-active {
    color: white;
    background: $site-10-primary-color;
  }

  .questions_section #ask-question-form button {
    background-color: $site-10-primary-color;
  }

  .about-the-tour-table a {
    color: $site-10-primary-color;
  }

  .tour-section.tour-section-images .tour-gallery-button {
    color: $site-10-secondary-color;
  }

  .guides-block .private-guide a .private-guide-name {
    color: $site-10-primary-color;
  }

  .tour-section .booking-form-main-item .next_available_date a {
    color: $site-10-primary-color;
  }

  .tour-section .booking-form-main-item-header {
    color: $site-10-primary-color;
  }

  .tour-section .booking-form-main-item-price .main-text {
    color: $site-10-primary-color;
  }

  .tour-section .booking-form-main-item-select input, .tour-section .booking-form-main-item-select span[role="combobox"] {
    -webkit-text-fill-color: $site-10-primary-color;
  }

  .tour-section .booking-form-main-item-select input, .tour-section .booking-form-main-item-select span[role="combobox"] {
    border-color: $site-10-primary-color;
    color: $site-10-primary-color;
  }

  .green-text, .booking .booking-columns a, .booking .booking-columns .booking-right-column .booking-right-content table .booking-right-price {
    color: $site-10-primary-color;
  }

  .booking .booking-columns .booking-left-column .booking-confirm-button button {
    background-color: $site-10-primary-color;
  }

  .green-text, .booking .booking-columns a, .booking .booking-columns .booking-right-column .booking-right-content table .booking-right-price {
    color: $site-10-primary-color;
  }

  .booking .booking-columns .booking-left-column .booking-payment-details-line-price.pay-now {
    color: $site-10-primary-color;
  }

  .booking .booking-columns .booking-left-column .booking-inputs input[type="radio"]:checked + label:before {
    background-color: $site-10-primary-color;
    border-color: darken($site-10-primary-color, 25);
  }

  .tour-section .booking-form-ribbon {
    background-color: $site-10-secondary-color;

    &:before {
      border-color: transparent rgba($site-10-secondary-color, 0.4) transparent transparent;
    }
  }


  .button {
    border-color: $site-10-primary-color;
    color: $site-10-primary-color;


    &.next {
      background: url(/static/img/sidetrip/icons/Orange/icon_arrow_right_orange.svg) no-repeat right 20px center;
      background-size: 20px auto;
    }

    &.strong {
      color: white;
      background-color: $site-10-primary-color;
    }

    &.strong.next {
      border-color: $site-10-primary-color;
      background-color: $site-10-primary-color;
      background-image: url(/static/img/icon_arrow_right_white.svg);
    }
  }

  .ui-button .ui-icon-triangle-1-s {
    background: url(/static/img/sidetrip/icons/Orange/icon_arrow_expand_orange.svg) no-repeat center center;
  }


  h2.decorated_a {
    border-color: $site-10-primary-color;
  }

  h2.decorated {
    color: $site-10-primary-color;

    &:before {
      background: linear-gradient(
                      to right,
                      $site-10-primary-color 0%,
                      $site-10-primary-color 289px,
                      rgba(255, 255, 255, 0) 289px
      );
    }

    &:after {
      background: linear-gradient(
                      to left,
                      $site-10-primary-color 0%,
                      $site-10-primary-color 289px,
                      rgba(255, 255, 255, 0) 289px)
    }


  }

  #terms-modal .flatpage-content .decorated {
    color: $site-10-primary-color;
  }
  .return-to-booking.button {
    color: white;
    background-color: $site-10-primary-color;
  }

}
